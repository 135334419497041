import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import { Arrow, HeaderContainer, HeaderContent, Logo, Subtitle, Title, TopBarContainer } from './styles';

interface HeaderComponentProps {
  title: string;
  subtitle?: string;
}

const Header: React.FC<HeaderComponentProps> = ({ title, subtitle, children }) => {
  return (
    <>
      <HeaderContainer>
        <TopBarContainer>
          <Link to="/">
            <Arrow />
          </Link>
          <Logo src={logo} />
        </TopBarContainer>

        <HeaderContent>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}

          {children}
        </HeaderContent>
      </HeaderContainer>
    </>
  );
};

export default Header;
