import React from 'react';

import { ArticleCard, Clickable, Container, Description, Img, TimeLinePoint } from './styles';

import newspaperIcon from './icons/newspaper';
import imagePlaceholder from '../../assets/images/articlePlaceholder.png';

export interface ArticleProps {
  title: string;
  image: string;
  link: string;
  description: string;
  date: string;
}


const ArticleItem: React.FC<ArticleProps> = ({ title, link, date, description, image }): JSX.Element => {
  function currentDateTime(stringDate: string): string {
    const data = new Date(stringDate);
    const day = data.getDate().toString().padStart(2, '0');
    const month = (data.getMonth() + 1).toString().padStart(2, '0');
    const year = data.getFullYear();
    const hour = data.getHours();
    const minutes = data.getMinutes();
    return `${day}/${month}/${year} - ${hour}:${minutes}`;
  }

  return (
    <>
      <Clickable href={link}>
        <Container>
          <TimeLinePoint datetime={currentDateTime(date)} icon={newspaperIcon} />
          <ArticleCard title={title}>
            <Img src={image || imagePlaceholder} alt={title} />
            <Description>{description}</Description>
          </ArticleCard>
        </Container>
      </Clickable>
    </>
  );
};

export default ArticleItem;
