import React from 'react';

const newspaperIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508 508" width="32" height="32">
  <circle cx="254" cy="254" r="254" fill="#84dbff" />
  <path fill="#e6e9ee" d="M362 398l-242 28.8V88l242-28.8z" />
  <path fill="#fff" d="M120 88h268.4v338.8H120z" />
  <g fill="#ff7058">
    <path
      d="M183.6 158l-19.2-28v25.6c0 2-1.6 3.6-3.6 3.6s-3.6-1.6-3.6-3.6V118c0-2 1.6-3.6 3.6-3.6 1.2 0 2.4.4 3.2 1.6l19.2 27.6V118c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6v37.6c0 2-1.6 3.6-3.6 3.6-1.2.4-2.4-.4-3.2-1.2zM236.8 155.6c0 2-1.6 3.6-3.6 3.6h-20.4c-2 0-3.6-1.6-3.6-3.6V118c0-2 1.6-3.6 3.6-3.6h20.4c2 0 3.6 1.6 3.6 3.6s-1.6 3.6-3.6 3.6h-16.8v11.2h13.2c2 0 3.6 1.6 3.6 3.6s-1.6 3.6-3.6 3.6h-13.2v11.2h16.8c1.6.8 3.6 2.4 3.6 4.4zM310 118.4c0 .4 0 .8-.4 1.6L294 157.2c-.4 1.6-2 2.4-3.2 2.4-1.6 0-2.8-.8-3.6-2.4l-4.8-12-5.2 12c-.4 1.6-2 2.4-3.2 2.4-1.6 0-2.8-.8-3.6-2.4L254.8 120c0-.4-.4-.8-.4-1.6 0-2 1.6-3.6 3.6-3.6 1.6 0 2.8.8 3.2 2.4l12 29.2 4.4-10.4-6.4-15.6c0-.4-.4-.8-.4-1.6 0-2 1.6-3.6 3.6-3.6 1.6 0 2.8.8 3.2 2.4l3.6 8.8 3.6-8.8c.4-1.6 2-2.4 3.2-2.4 2.4 0 3.6 2 3.6 3.6 0 .4 0 .8-.4 1.6l-6.8 15.6 4.4 10.4 12-29.2c.4-1.6 2-2.4 3.2-2.4.4 0 1.2 0 1.6.4 3.6.4 4.4 1.6 4.4 3.2zM351.2 144.4v.8c0 3.6-1.6 7.2-4 10-2.8 2.8-6.8 4.4-10.8 4.4h-.4c-5.6 0-10-3.2-12.4-8-.4-.4-.4-1.2-.4-1.6 0-1.6.8-2.8 2-3.2.4-.8.8-.8 1.6-.8 1.6 0 2.8.8 3.6 2 1.2 2.4 3.2 3.6 6 3.6h.4c2.4 0 4-.8 5.6-2 1.2-1.2 2-2.8 2-4.8v-.4c0-2.8-5.6-5.6-8.4-6.4h-.4c-6.8-2.4-10-6.4-10-12.4v-.4c0-3.2 1.6-6.4 4.4-8.4 2.4-1.6 5.6-2.8 8.8-2.8h.4c2.8 0 5.6 1.2 7.6 2.8.8.8 1.6 2 1.6 2.8s-.4 1.6-.8 2.4c-.8.8-2 1.6-3.2 1.6-.8 0-1.6 0-2.4-.8s-2.4-1.2-3.2-1.2h-.4c-3.2 0-5.6 1.6-5.6 4v.4c0 3.2 2.4 4.4 4.8 5.2 2 .8 13.2 4.4 13.6 13.2z" />
  </g>
  <g fill="#e6e9ee">
    <path
      d="M138.4 185.2h100v9.2h-100zM269.6 185.2h100v9.2h-100zM138.4 211.6h100v9.2h-100zM138.4 238.4h100v9.2h-100zM138.4 264.8h100v9.2h-100z" />
  </g>
  <g fill="#acb3ba">
    <path d="M269.6 212.8h100v80h-100zM138.4 300.4h100v80h-100z" />
  </g>
  <g fill="#e6e9ee">
    <path
      d="M269.6 318.4h100v9.2h-100zM269.6 344.8h100v9.2h-100zM269.6 371.6h100v9.2h-100zM138.4 398h100v9.2h-100zM269.6 398h100v9.2h-100z" />
  </g>
</svg>;

export default newspaperIcon;
