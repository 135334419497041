import { Button, Card } from 'react-rainbow-components';

import styled from 'styled-components';

export const Container = styled.section`
  width: 100vw;
  min-height: 100vh;

  background-color: #f4f6f9;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 30px;
`;

export const ImageBox = styled.img`
  width: 64px;
  margin: 0 auto;
`;

export const RedirectButton = styled(Button)`
  margin: 0 auto;
  width: 150px;
`;

export const CardBox = styled(Card)`
  height: 350px;
  width: 350px;

  padding: 50px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  margin: 0 auto;

  @media (max-width: 425px) {
    height: 300px;
    width: 300px;
  }
`;
