import { Button, Card } from 'react-rainbow-components';

import styled from 'styled-components';

export const HomeButton = styled(Button)`
  margin: 16px auto;
  min-width: 300px;
  max-width: calc(100vw - 16px);
`;

export const CenterContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const CardBox = styled(Card)`
  height: 450px;
  padding: 32px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

export const ImageBox = styled.img`
  height: 64px;
  margin: 0 auto;
`;

export const Header = styled.header`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

export const Brand = styled.a`
  text-decoration: none;
  display: flex;
  color: #70bc50;
`;

export const Logo = styled.img`
  width: 32px;
  height: 32px;
  margin: 0 8px;
`;
