import React from 'react';
import { ToastProvider } from 'react-toast-notifications';

import AppRouter from './AppRouter';
import GlobalStyles from './global/GlobalStyles';

const App = (): JSX.Element => (
  <ToastProvider>
    <AppRouter />
    <GlobalStyles />
  </ToastProvider>
);

export default App;
