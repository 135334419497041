import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import { Container } from '../../styles';
import { Brand, Header, HomeButton, Logo } from './styles';

import ROUTES from '../../utils/routes';

const Home = (): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Header>
        <Brand href="/">
          <Logo src={logo} />
          <h1>Pacientes</h1>
        </Brand>
      </Header>

      <Container>
        <HomeButton
          variant="brand"
          onClick={(): void => {
            history.push(ROUTES.ARTIGOS);
          }}>
          <span>Artigos</span>
        </HomeButton>
        <HomeButton
          variant="brand"
          onClick={(): void => {
            history.push(ROUTES.SUGESTAO);
          }}>
          <span>Enviar sugestão</span>
        </HomeButton>
        <HomeButton
          variant="brand"
          onClick={(): void => {
            history.push(ROUTES.AVALIACAO);
          }}>
          <span>Avaliar conteúdos</span>
        </HomeButton>
        <HomeButton
          variant="brand"
          onClick={(): void => {
            history.push(ROUTES.SOLICITACAO);
          }}>
          <span>Solicitar conteúdos </span>
        </HomeButton>
      </Container>
    </>
  );
};

export default Home;
