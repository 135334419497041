import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import { CardBox, Container, ImageBox, RedirectButton, Title } from './styles';

const NotFound = (): JSX.Element => {
  const history = useHistory();

  const handleClick = (): void => {
    history.push('/');
  };

  return (
    <Container>
      <CardBox>
        <ImageBox src={logo} alt="logo" />
        <Title>Página não encontrada!</Title>
        <RedirectButton label="Página inicial" variant="neutral" onClick={handleClick} />
      </CardBox>
    </Container>
  );
};

export default NotFound;
