import React, { useEffect, useState } from 'react';
import { Button, VisualPicker } from 'react-rainbow-components';
import { useToasts } from 'react-toast-notifications';

import brand from '../../assets/images/brand.svg';
import file from '../../assets/images/file-play.svg';
import Header from '../../components/Header';
import api from '../../services/api';
import { Container } from '../../styles';
import { CardBox, CenterContainer, ImageBox, Option, OptionText } from './styles';

interface Media {
  id: number;
  name: string;
}

const Rate = (): JSX.Element => {
  const [values, setValues] = useState<Media[]>();
  const [selectedString, setSelectedString] = useState<string | string[]>('');
  const { addToast } = useToasts();

  const fetchData = async (): Promise<void> => {
    const response = await api.get<Media[]>('/medias');
    const newArray: Media[] = response.data.map((media) => {
      return media;
    });
    setValues(newArray);
  };

  useEffect(() => {
    fetchData();
  }, []);

  function changeValueHandler(name: string | string[]): void {
    setSelectedString(name);
  }

  async function sendRequest(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    interface Data {
      message: string | string[];
      status: string;
    }

    event.preventDefault();

    const selectedValue = values?.filter((media) => {
      if (media.name === selectedString) {
        return media;
      }
    });

    const mediaReq = {
      contentId: selectedValue ? selectedValue[0].id : 999,
    };

    try {
      const response = await api.post<Data>('/vote', mediaReq);

      const messages = [response.data.message].flatMap((e) => e);
      messages.forEach((message) => addToast(message, { appearance: 'success', autoDismiss: true }));
    } catch (error) {
      const messages = [error.response.data.message].flatMap((e) => e);
      messages.forEach((message) => addToast(message, { appearance: 'warning', autoDismiss: true }));
    }
  }

  return (
    <>
      <Header title="Solicitação" subtitle="Aqui você pode solicitar o conteúdo que será exibido!" />
      <Container>
        <CenterContainer>
          <form onSubmit={sendRequest}>
            <CardBox>
              <ImageBox src={brand} alt="logo" />

              <VisualPicker
                id="visual-picker-component-1"
                label="Selecione uma opção"
                value={selectedString}
                onChange={changeValueHandler}
                size="small">
                {values?.[0] && (
                  <Option name={values?.[0].name} style={{ margin: '8px' }}>
                    <img src={file} alt="file-play" />
                    <OptionText>{values?.[0].name}</OptionText>
                  </Option>
                )}
                {values?.[1] && (
                  <Option name={values?.[1].name} style={{ margin: '8px' }}>
                    <img src={file} alt="file-play" />
                    <OptionText>{values?.[1].name}</OptionText>
                  </Option>
                )}
                {values?.[2] && (
                  <Option name={values?.[2].name} style={{ margin: '8px' }}>
                    <img src={file} alt="file-play" />
                    <OptionText>{values?.[2].name}</OptionText>
                  </Option>
                )}
              </VisualPicker>

              <Button type="submit" variant="outline-brand">
                <span>Enviar solicitação</span>
              </Button>
            </CardBox>
          </form>
        </CenterContainer>
      </Container>
    </>
  );
};

export default Rate;
