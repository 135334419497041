import { FaArrowLeft } from 'react-icons/fa';

import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
`;

export const TopBarContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 1.2rem;
`;

export const Logo = styled.img`
  width: 42px;
  height: 42px;
`;

export const Arrow = styled(FaArrowLeft)`
  height: 2rem;
  color: #027f75;
  width: 1.6rem;

  :hover {
    color: #70bc50;
  }
`;

export const HeaderContent = styled.div`
  width: 88%;
  position: relative;
  margin: 0.5rem auto;
`;

export const Title = styled.strong`
  color: rgba(63, 73, 84, 1);
  font-size: 1.4rem;
  line-height: 3rem;
  margin-top: 2rem;
`;

export const Subtitle = styled.p`
  color: rgba(63, 73, 84, 1);
  max-width: 20rem;
  font-size: 1.2rem;
  line-height: 2.6rem;
  padding: 0.8rem 0 2rem;
`;
