import React, { useEffect, useState } from 'react';

import ArticleItem from '../../components/Article';
import Header from '../../components/Header';
import api from '../../services/api';
import { Container } from './styles';
import Article from './types';

const Articles = (): JSX.Element => {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await api.get<Article[]>('/articles');
      setArticles(response.data);
    };

    fetchData();
  }, []);
  return (
    <>
      <Header title="Artigos" />

      <Container>
        {articles.map((article, index) => {
          return (
            <ArticleItem
              key={index}
              date={article.date}
              description={article.description}
              image={article.image}
              link={article.link}
              title={article.title}
            />
          );
        })}
      </Container>
    </>
  );
};

export default Articles;
