import { Card, Rating, VisualPickerOption } from 'react-rainbow-components';

import styled from 'styled-components';

export const CenterContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const CardBox = styled(Card)`
  height: 450px;
  padding: 32px;
  min-width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

export const ImageBox = styled.img`
  height: 64px;
  margin: 0 auto;
`;

export const Stars = styled(Rating)`
  margin: 0 auto;

  & > span {
    width: 30px;
  }
  & > legend {
    margin: 0.5rem auto;
  }
`;

export const Option = styled(VisualPickerOption)`
  display: flex;

  justify-content: center;
  align-items: center;
`;

export const OptionText = styled.p`
  border: 0;
  padding: 0;
  color: rgba(63, 73, 84, 1);
  font-size: 12px;
  line-height: 1.5;
  margin: 0 auto 0.25rem auto;
  text-align: center;

  white-space: nowrap;
  width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
