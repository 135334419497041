import React, { useEffect, useState } from 'react';
import { Button, Lookup } from 'react-rainbow-components';
import { useToasts } from 'react-toast-notifications';

import brand from '../../assets/images/brand.svg';
import Header from '../../components/Header';
import api from '../../services/api';
import { Container } from '../../styles';
import { CardBox, CenterContainer, ImageBox, Stars } from './styles';

interface ContentData {
  id?: string;
  name?: string;
  label?: string;
}

interface LookUpOption {
  value?: ContentData;
  label?: string;
  id?: string;
  options?: LookUpOption[];
}

const Rate = (): JSX.Element => {
  const [content, setContent] = useState<LookUpOption>({});
  const [rateValue, setRateValue] = useState<number>(0);
  const label = ['Avaliação', 'Ruim', 'Razoável', 'Bom', 'Muito bom', 'Excelente'];

  const { addToast } = useToasts();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async (): Promise<void> => {
    const response = await api.get<ContentData[]>('/medias');
    const lookupOptions: LookUpOption[] = response.data.map((value) => {
      return { label: value.name, id: value.id };
    });
    setContent({ options: lookupOptions });
  };

  function filter(query: string, options: LookUpOption[]) {
    if (query) {
      return options.filter((item: LookUpOption) => {
        const regex = new RegExp(query.trim(), 'i');
        return regex.test(item?.label ?? '');
      });
    }
    return [];
  }

  function search(value: string) {
    if (content.options && content.label) {
      setContent({
        options: filter(value, content.options),
      });
    }
  }

  function changeRateValueHandler({ currentTarget }: React.ChangeEvent<any>): void {
    setRateValue(currentTarget.value);
  }

  async function sendRating(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    interface Data {
      message: string | string[];
      status: string;
    }

    event.preventDefault();
    const request = {
      contentId: content.value?.id ?? '',
      rate: rateValue,
    };

    try {
      const response = await api.post<Data>('/rate', request);

      const messages = [response.data.message].flatMap((e) => e);
      messages.forEach((message) => addToast(message, { appearance: 'success', autoDismiss: true }));

      setContent({ label: undefined });
      setRateValue(0);
    } catch (error) {
      const messages = [error.response.data.message].flatMap((e) => e);
      messages.forEach((message) => addToast(message, { appearance: 'warning', autoDismiss: true }));
    }
  }

  return (
    <>
      <Header title="Avaliação" subtitle="Aqui você pode avaliar o conteúdo que foi exibido!" />
      <Container>
        <CenterContainer>
          <form onSubmit={sendRating}>
            <CardBox>
              <ImageBox src={brand} alt="logo" />
              <Lookup
                label="Conteúdo"
                placeholder="Procurar Conteúdo"
                options={content.options}
                value={content.value}
                onChange={(value) => setContent({ value: value ?? undefined })}
                onSearch={search}
                onClick={fetchData}
              />
              <Stars value={rateValue} label={label[rateValue]} onChange={changeRateValueHandler} />
              <Button type="submit" variant="outline-brand">
                <span>Enviar avaliação</span>
              </Button>
            </CardBox>
          </form>
        </CenterContainer>
      </Container>
    </>
  );
};

export default Rate;
