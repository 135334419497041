import { Card, TimelineMarker } from 'react-rainbow-components';

import styled from 'styled-components';

export const ArticleCard = styled(Card)`
  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    padding: 4px 12px;
  }
`;

export const TimeLinePoint = styled(TimelineMarker)`
  margin: 0 auto;

  @media (max-width: 425px) {
    width: 300px;
  }
`;

export const Container = styled.div`
  width: 500px;
  margin: auto;
  margin-top: 36px;

  @media (max-width: 425px) {
    width: 300px;
  }
`;

export const Clickable = styled.a`
  text-decoration: none;

  margin: 0 auto;

  @media (max-width: 425px) {
    width: 300px;
  }
`;

export const Img = styled.img`
  max-height: 500px;
  max-width: 500px;
  padding: 0 20px 0 20px;

  @media (max-width: 425px) {
    max-width: 300px;
    max-height: 300px;
  }
`;

export const Description = styled.span`
  display: block;
  text-align: justify;
`;
