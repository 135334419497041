import { Card } from 'react-rainbow-components';

import styled from 'styled-components';

export const CenterContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const CardBox = styled(Card)`
  height: 450px;
  padding: 32px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

export const ImageBox = styled.img`
  height: 64px;
  margin: 0 auto;
`;
