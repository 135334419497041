import React, { useState } from 'react';
import { Button, CodeInput, Input, RadioGroup } from 'react-rainbow-components';
import { useToasts } from 'react-toast-notifications';

import brand from '../../assets/images/brand.svg';
import Header from '../../components/Header';
import api from '../../services/api';
import { Container } from '../../styles';
import { CardBox, CenterContainer, ImageBox } from './styles';

const Articles = (): JSX.Element => {
  const [suggestionFilled, setSuggestionFilled] = useState<boolean>(false);
  const [suggestion, setSuggestion] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [age, setAge] = useState<string>('00');

  const { addToast } = useToasts();

  const genderOptions = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
  ];

  function changeSuggestionHandler({ currentTarget }: React.ChangeEvent<HTMLInputElement>): void {
    setSuggestion(currentTarget.value);
  }

  function changeGenderHandler({ currentTarget }: React.ChangeEvent<any>): void {
    setGender(currentTarget.value);
  }

  async function sendSuggestion(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    interface Data {
      message: string | string[];
      status: string;
    }

    event.preventDefault();

    try {
      const response = await api.post<Data>('suggestion', {
        suggestion,
        gender,
        age: +age,
      });

      const messages = [response.data.message].flatMap((e) => e);
      messages.forEach((message) => addToast(message, { appearance: 'success', autoDismiss: true }));

      setAge('00');
      setGender('');
      setSuggestion('');
      setSuggestionFilled(false);
    } catch (error) {
      const messages = [error.response.data.message].flatMap((e) => e);
      messages.forEach((message) => addToast(message, { appearance: 'warning', autoDismiss: true }));
    }
  }

  return (
    <>
      <Header title="Sugestão" subtitle="Aqui você pode sugerir algum conteúdo para que seja exibido!" />
      <Container>
        <CenterContainer>
          <form onSubmit={sendSuggestion}>
            <CardBox>
              <ImageBox src={brand} alt="logo" />
              <Input
                value={suggestion}
                onChange={changeSuggestionHandler}
                onBlur={() => {
                  setSuggestionFilled(!!suggestion);
                }}
                label="Conteúdo"
              />
              <CodeInput
                value={age}
                onChange={(age) => setAge(age.padStart(2, '0'))}
                onKeyDown={(e) => setAge(e.key !== 'Backspace' ? age.replace(/^0/, '') : age)}
                disabled={!suggestionFilled}
                label="Idade"
                length={2}
              />
              <RadioGroup
                options={genderOptions}
                value={gender}
                onChange={changeGenderHandler}
                label="Gênero"
              />
              <Button type="submit" variant="outline-brand">
                <span>Enviar sugestão</span>
              </Button>
            </CardBox>
          </form>
        </CenterContainer>
      </Container>
    </>
  );
};

export default Articles;
