import { ActivityTimeline, Card } from 'react-rainbow-components';

import styled from 'styled-components';

export const ImageBox = styled(Card)`
  max-width: 100%;
  width: 500px;

  max-height: 100%;
  height: 300px;

  margin: 0 auto;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const TimeLineContainer = styled.div`
  height: 500px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.section`
  background-color: #f4f6f9;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px;
`;

export const Timeline = styled(ActivityTimeline)``;
