import styled from 'styled-components';

export const Container = styled.section`
  background-color: #f4f6f9;
  height: calc(100vh - 64px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 64px;

  @media (max-width: 720px) {
    padding: 8px;
  }
`;

export default Container;
