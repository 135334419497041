import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Articles from './pages/Articles';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Rate from './pages/Rate';
import Request from './pages/Request';
import Suggestion from './pages/Suggestion';

const AppRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/artigos" component={Articles} exact />
        <Route path="/sugestao" component={Suggestion} exact />
        <Route path="/avaliacao" component={Rate} exact />
        <Route path="/solicitacao" component={Request} exact />
        <Route path="/*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
